import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Skeleton from '@mui/material/Skeleton';
import LineChart from './LineChart';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { mongoDataConstants } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

function Spectrum({ object }) {
  const classes = useStyles();

  const { scans, photo } = object;
  const { white, sample } = scans;

  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(new Array(4).fill(false));

  const handleExpandClick = (index) => {
    setExpanded((prevState) => prevState.map((item, idx) => (idx === index ? !item : item)));
  };

  const downloadImage = () => {
    dispatch({
      type: mongoDataConstants.MEMS_DATA_MAKE_PHOTO_REQUEST,
      payload: {
        id: object._id.$oid,
        url: object.photo.url.slice(object.photo.url.lastIndexOf('/') + 1)
      },
      collection: 'mems.files'
    });
  };

  const getReflectances = () => {
    if ('standardizedReflectance' in sample) {
      return {
        reflectance: sample.reflectance,
        standardizedReflectance: sample.standardizedReflectance
      };
    }
    return sample.reflectance;
  };

  return (
    <Box margin={1}>
      <Grid container spacing={3}>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Card>
            <CardHeader title="White reference" />
            <Divider />
            <CardMedia>
              <LineChart
                spectra={white.spectrum}
                MinWL={white.parameters.MeasSettings.Wavelengths.MinWL}
                MaxWL={white.parameters.MeasSettings.Wavelengths.MaxWL}
                Step={white.parameters.MeasSettings.Wavelengths.Step}
              />
            </CardMedia>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded[0]
                })}
                onClick={() => handleExpandClick(0)}
                aria-expanded={expanded[0]}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded[0]} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>
                  {JSON.stringify(white.parameters.MeasSettings, null, 1)}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Card>
            <CardHeader title="Sample's raw spectrum" />
            <Divider />
            <CardMedia>
              <LineChart
                spectra={sample.spectrum}
                MinWL={sample.parameters.MeasSettings.Wavelengths.MinWL}
                MaxWL={sample.parameters.MeasSettings.Wavelengths.MaxWL}
                Step={sample.parameters.MeasSettings.Wavelengths.Step}
              />
            </CardMedia>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded[1]
                })}
                onClick={() => handleExpandClick(1)}
                aria-expanded={expanded[1]}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded[1]} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>
                  {JSON.stringify(sample.parameters.MeasSettings, null, 1)}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Card>
            <CardHeader title="Sample's reflectance" />
            <Divider />
            <CardMedia>
              <LineChart
                spectra={getReflectances()}
                MinWL={sample.parameters.MeasSettings.Wavelengths.MinWL}
                MaxWL={sample.parameters.MeasSettings.Wavelengths.MaxWL}
                Step={sample.parameters.MeasSettings.Wavelengths.Step}
                isReflectance
              />
            </CardMedia>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded[2]
                })}
                onClick={() => handleExpandClick(2)}
                aria-expanded={expanded[2]}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded[2]} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>
                  Moisture content is:
                  {' '}
                  {sample.moisture ? sample.moisture : 'not recorded'}
                </Typography>
                <Typography paragraph>
                  {JSON.stringify(sample.parameters.MeasSettings, null, 1)}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Card>
            <CardHeader title="Sample photo" />
            <Divider />
            {photo && photo.loading && <Skeleton variant="rectangular" height={200} />}
            {photo && photo.base64String && (
              <Zoom>
                <img
                  className={classes.media}
                  src={`data:image/${photo.format};base64,${photo.base64String}`}
                  alt="sample"
                />
              </Zoom>
            )}
            {(!photo || photo.format !== 'jpeg') && (
              <CardContent>
                <Typography>No photo was taken for this sample.</Typography>
              </CardContent>
            )}
            {photo && photo.format === 'jpeg' && photo.url ? (
              <>
                <CardActions disableSpacing>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<CloudDownloadIcon />}
                    onClick={downloadImage}
                    disabled={
                      !!(photo.loading || photo.base64String)
                    }
                  >
                    Download photo
                  </Button>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded[3]
                    })}
                    onClick={() => handleExpandClick(3)}
                    aria-expanded={expanded[3]}
                    aria-label="show more"
                    size="large"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={expanded[3]} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>
                      {JSON.stringify(photo.exif, null, 1)}
                    </Typography>
                  </CardContent>
                </Collapse>
              </>
            ) : null}
          </Card>
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <Card>
            <CardHeader title="Sample analysis" />
            <Divider />
            <CardContent>
              {object.analysis && 'SOM' in object.analysis && (
                <Typography>
                  SOM is
                  {object.analysis.SOM.toFixed(2)}
                  {' '}
                  %
                </Typography>
              )}
              {object.analysis && 'SOC' in object.analysis && (
                <Typography>
                  SOC is
                  {object.analysis.SOC.toFixed(2)}
                  {' '}
                  %
                </Typography>
              )}
              {object.analysis && object.analysis.error && (
              <Typography>
                An error occurred during the automated analysis:
                {object.analysis.error}
              </Typography>
              )}
              {!object.analysis && (
                <Typography>No automated analysis was conducted for this sample.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

Spectrum.propTypes = {
  object: PropTypes.object.isRequired
};

export default Spectrum;
